import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import { toast } from "react-toastify";
import {
    Card,
    Form,
    Button,
    Col,
    Row,
    Container,
    InputGroup,
} from "react-bootstrap"
import one from "../../../assets/images/pro3/1.jpg";
import user from "../../../assets/images/user.png";
import { useGlobal } from "../../../services/Context";
import { useParams } from "react-router";

const Edit_product = ({ afterPaste, onBlur, onChange }) => {
    const { editProduct, categories, getCategories, isLoading, getProduct, aproduct, uploadFile } = useGlobal()
    const params = useParams()

    const [product, setproduct] = useState(null)
    const [variant, setvariant] = useState(product?.variants);
    const [size, setsize] = useState(product?.sizes);
    const [file, setFile] = useState();
    const [dummyimgs, setDummyimgs] = useState([
        { img: user },
        { img: user },
        { img: user },
        { img: user },
        { img: user },
        { img: user },
    ]);
    useEffect(() => {
        if (categories === null) {
            getCategories()
        }
        if (params?.id !== aproduct?._id) {
            getProduct(params?.id)
        }
        setproduct(aproduct)
        setvariant(aproduct?.variants)
        setsize(aproduct?.sizes)
    }, [aproduct])
    const handleChange = (event) => {
        if (event.target.name === 'images' ||  event.target.name === 'images2') {
            setproduct({
                ...product,
                [event.target.name]: event.target.files[0]
            })
        } else if(event.target.type === "checkbox"){
			setproduct({
				...product,
				[event.target.name]: event.target.checked
			})
		}else {
            setproduct({
                ...product,
                [event.target.name]: event.target.value
            })
        }
        console.log(product)
    };

    const handleVariantChange = (event, index) => {
        const data = variant?.map((item, idx) => {
            if (idx === index) {
                item = event.target.name === "name" ? {
                    ...item,
                    [event.target.name]: event.target.value
                } : {
                    ...item,
                    [event.target.name]: (+event.target.value / +product.quantity ) * 100
                }
            }
            return item
        })
        setvariant(data)
        console.log(data)
    };
    const handleSizeChange = (event, index) => {
        const data = size?.map((item, idx) => {
            if (idx === index) {
                item = event.target.name === "name" ? {
                    ...item,
                    [event.target.name]: event.target.value
                } : {
                    ...item,
                    [event.target.name]: (+event.target.value / +product.quantity ) * 100
                }
            }
            return item
        })
        setsize(data)
        console.log(data)
    };

    const handleImagesChange = (event, index) => {
		var formdata = new FormData();
		formdata.append('file', event.target.files[0])
		uploadFile(formdata)
			.then(response => {
				if (response.data.status) {
					let data = variant;
					data[index] = {
						...data[index],
						images: [...data[index].images, ...response?.data?.data]
					}
					setvariant(data)
				}
				else {
					toast.error(response.error);
				}

			}).catch((res) => {

				toast.error(res.message);

			})
	}
    //	image upload

    const _handleImgChange = (e, i) => {
        e.preventDefault();
        let reader = new FileReader();
        const image = e.target.files[0];
        reader.onload = () => {
            dummyimgs[i].img = reader.result;
            setFile({ file: file });
            setDummyimgs(dummyimgs);
        };
        reader.readAsDataURL(image);
    };
    const addVariant = () => {
        setvariant([
            ...variant,
            {
                name: '',
                percentage: '',
                images: []
            }

        ])
    }
    const removeVariant = (id) => {
        const newList = variant.map((item, index) => {
            if (index !== id) {
                return item
            }
        })
        setvariant(newList)
    }
    const addSize = () => {
        setsize([
            ...size,
            {
                name: '',
                percentage: ''
            }

        ])
    }
    const removeSize = (id) => {
        const newList = size.map((item, index) => {
            if (index !== id) {
                return item
            }
        })
        setsize(newList)
    }

    const handleValidSubmit = () => { };
    const saveProduct = () => {
        // e.preventDefault()
        var formdata = new FormData();
        formdata.append("productId", params?.id);
        formdata.append("name", product?.name);
        formdata.append("realPrice", +product?.realPrice);
        formdata.append("discount", +product?.discount);
        // formdata.append("images", product?.images[0] || product?.images);
        // formdata.append("images", product?.images[1] || product?.images2);
        formdata.append("variants", JSON.stringify(variant));
        formdata.append("sizes", JSON.stringify(size));
        formdata.append("categoryId", product?.categoryId);
        formdata.append("quantity", +product?.quantity);
        formdata.append("shipping", +product?.shipping);
        formdata.append("description", product?.description);
        formdata.append("isSpecial", product?.isSpecial.toString());
        editProduct(formdata)
    }
    if (isLoading && (aproduct === null || params?.id !== aproduct?._id)) {
        return <h1>Loading...</h1>
    } else {
        return (
            <Fragment>
                <Breadcrumb title="Edit Product" parent="Physical" />

                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header>
                                    <h5>Edit Product</h5>
                                </Card.Header>
                                <Card.Body>

                                    <Form onSubmit={(e) => {
                                        e.preventDefault()
                                        saveProduct()
                                    }}>

                                        {/* <Form.Row>
                                            <Form.Label>Select Product Image : </Form.Label>
                                            <input
                                                type="file"
                                                name="images"
                                                defaultValue={aproduct?.images[0] || ""}
                                                onChange={handleChange}
                                            />
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Label>Select Product Image : </Form.Label>
                                            <input
                                                type="file"
                                                name="images2"
                                                defaultValue={aproduct?.images[1] || ""}
                                                onChange={handleChange}
                                            />
                                        </Form.Row> */}
                                        <Form.Row>
                                            <Form.Label>Select Category</Form.Label>
                                            <Col xs="12">

                                                <select onChange={handleChange} name="categoryId" defaultValue={product?.categoryId} className="form-control form-control-md">
                                                    <option>Select...</option>
                                                    {
                                                        categories?.map((item, index) => (
                                                            <option key={index} value={item._id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>

                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs="6">
                                                <Form.Label>Product Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="name of product"
                                                    name="name"
                                                    defaultValue={product?.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs="6">
                                                <Form.Label>Product Quantity</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="quantity"
                                                    defaultValue={product?.quantity}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs="4">
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter Product Price"
                                                    name="realPrice"
                                                    defaultValue={product?.realPrice}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Col>
                                            <Col xs="4">
                                                <Form.Label>Discount</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="number"
                                                        name="discount"
                                                        defaultValue={product?.discount}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <InputGroup.Text>
                                                        %
														</InputGroup.Text>
                                                </InputGroup>

                                            </Col>
                                            <Col xs="4">
                                                <Form.Label>Shipping</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="shipping"
                                                    defaultValue={product?.shipping}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs="12"><Form.Label>Sizes Available</Form.Label></Col>
                                            {
                                                size?.map((item, index) => (
                                                    <Col xs="4">
                                                        <Card style={{ padding: '10px' }}>
                                                            <b style={{ right: '10%', color: 'red', position: 'absolute', cursor: 'pointer' }} onClick={() => removeSize(index)}> X</b>
                                                            <p>Size {index + 1}</p>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="name of size"
                                                                    name="name"
                                                                    defaultValue={item?.name}
                                                                    onChange={(e) => handleSizeChange(e, index)}

                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="number available"
                                                                    name="percentage"
                                                                    defaultValue={parseInt((+item?.percentage / 100) * (+item?.quantity) ) }
                                                                    onChange={(e) => handleSizeChange(e, index)}
                                                                />
                                                            </Form.Group>
                                                        </Card>
                                                    </Col>

                                                ))
                                            }
                                            <Col xs="4">
                                                <Button onClick={addSize}>
                                                    Add Another Size
													</Button>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs="12"><Form.Label>Variants Available</Form.Label></Col>
                                            {
                                                variant?.map((item, index) => (
                                                    <Col xs="4">
                                                        <Card style={{ padding: '10px' }}>
                                                            <b style={{ right: '10%', color: 'red', position: 'absolute', cursor: 'pointer' }} onClick={() => removeVariant(index)}> X</b>
                                                            <p>Variant {index + 1}</p>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="name of variant"
                                                                    name="name"
                                                                    defaultValue={item?.name}
                                                                    onChange={(e) => handleVariantChange(e, index)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="number available"
                                                                    name="percentage"
                                                                    defaultValue={parseInt((+item?.percentage / 100) * (+item?.quantity) ) }
                                                                    onChange={(e) => handleVariantChange(e, index)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Row>
															<Form.Label>Variant Image 1 : </Form.Label>
															<input
																type="file"
																name="image"
																onChange={(e) => handleImagesChange(e, index)}
															/>
														</Form.Row>
														<Form.Row>
															<Form.Label>Variant Image 2 : </Form.Label>
															<input
																type="file"
																name="image"
																onChange={(e) => handleImagesChange(e, index)}
															/>
														</Form.Row>
                                                        </Card>
                                                    </Col>

                                                ))
                                            }
                                            <Col xs="4">
                                                <Button onClick={addVariant}>
                                                    Add Another Variant
													</Button>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs="12">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    placeholder="enter product description"
                                                    name="description"
                                                    required
                                                    defaultValue={product?.description}
                                                    onChange={handleChange}
                                                />
                                            </Col>

                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs="12">
                                                <br />
                                                <Form.Label>Check if it's a special product</Form.Label>
                                                <input
                                                    type="checkbox"
                                                    name="isSpecial"
                                                    style={{ marginLeft: '10px' }}
                                                    onChange={handleChange}
                                                    defaultValue={product?.isSpecial}
                                                    checked={product?.isSpecial}
                                                />
                                            </Col>

                                        </Form.Row>
                                        <Row>
                                            <Col xs="12">
                                                <Button type="submit" >{isLoading ? 'Loading...' : 'Submit'}</Button>
                                                {isLoading && <b> {" "}uploading all images, please wait a bit to submit</b>}
                                                
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
};

export default Edit_product;

