import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import CKEditors from "react-ckeditor-component";
import { toast } from "react-toastify";
import {
	Card,
	Form,
	Button,
	Col,
	Row,
	Container,
	InputGroup,
} from "react-bootstrap"
import one from "../../../assets/images/pro3/1.jpg";
import user from "../../../assets/images/user.png";
import { useGlobal } from "../../../services/Context";

const Add_product = ({ afterPaste, onBlur, onChange }) => {
	const { addProduct, categories, getCategories, isLoading, uploadFile, startLoading,endLoading } = useGlobal()

	useEffect(() => {
		if (categories === null) {
			getCategories()
		}
	}, [])
	const [product, setproduct] = useState({
		description: "",
		name: "",
		isSpecial: true

	})
	const [variant, setvariant] = useState([
		{
			name: "",
			percentage: "",
			images: []
		}
	]);
	const [size, setsize] = useState([
		{
			name: "",
			percentage: ""
		}
	]);
	const [file, setFile] = useState();
	const [dummyimgs, setDummyimgs] = useState([
		{ img: user },
		{ img: user },
		{ img: user },
		{ img: user },
		{ img: user },
		{ img: user },
	]);
	const [images, setimages] = useState([''])
	const handleChange = (event) => {
		if (event.target.name === 'images' || event.target.name === 'images2') {
			setproduct({
				...product,
				[event.target.name]: event.target.files[0]
			})
		} else if (event.target.type === "checkbox") {
			setproduct({
				...product,
				[event.target.name]: event.target.checked
			})
		} else {
			setproduct({
				...product,
				[event.target.name]: event.target.value
			})
		}
		console.log(product)
	};
	const handleImagesChange = (event, index) => {
		var formdata = new FormData();
		formdata.append('file', event.target.files[0])
		uploadFile(formdata)
			.then(response => {
				endLoading(0)
				if (response.data.status) {
					let data = variant;
					data[index] = {
						...data[index],
						images: [...data[index].images, ...response?.data?.data]
					}
					setvariant(data)
				}
				else {
					toast.error(response.error);
					
				}

			}).catch((res) => {

				toast.error(res.message);
				endLoading(0)

			})
	}
	const handleVariantChange = (event, index) => {

		const data = variant?.map((item, idx) => {
			if (idx === index) {
				item = event.target.name === "name" ? {
					...item,
					[event.target.name]: event.target.value
				} : event.target.name === "percentage" ? {
					...item,
					[event.target.name]: (+event.target.value / +product.quantity) * 100
				} : null

			}
			return item
		})
		setvariant(data)

	};
	const handleSizeChange = (event, index) => {
		const data = size?.map((item, idx) => {
			if (idx === index) {
				item = event.target.name === "name" ? {
					...item,
					[event.target.name]: event.target.value
				} : {
					...item,
					[event.target.name]: (+event.target.value / +product.quantity) * 100
				}
			}
			return item
		})
		setsize(data)
		console.log(data)
	};
	//	image upload

	const _handleImgChange = (e, i) => {
		e.preventDefault();
		let reader = new FileReader();
		const image = e.target.files[0];
		reader.onload = () => {
			dummyimgs[i].img = reader.result;
			setFile({ file: file });
			setDummyimgs(dummyimgs);
		};
		reader.readAsDataURL(image);
	};
	const addVariant = () => {
		setvariant([
			...variant,
			{
				name: '',
				percentage: '',
				images: []
			}

		])

	}
	const removeVariant = (id) => {
		const newList = variant.map((item, index) => {
			if (index !== id) {
				return item
			}
		})
		setvariant(newList)
		const newImages = images.map((item, index) => {
			if (index !== id) {
				return item
			}
		})
		setimages(newImages)
	}
	const addSize = () => {
		setsize([
			...size,
			{
				name: '',
				percentage: ''
			}

		])
	}
	const removeSize = (id) => {
		const newList = size.map((item, index) => {
			if (index !== id) {
				return item
			}
		})
		setsize(newList)
	}

	const handleValidSubmit = () => { };
	const saveProduct = () => {
		// e.preventDefault()
		var formdata = new FormData();

		formdata.append("name", product.name);
		formdata.append("realPrice", +product.realPrice);
		formdata.append("discount", +product.discount);
		images.map((item) => {
			formdata.append('images', item)
		})
		formdata.append("variants", JSON.stringify(variant));
		formdata.append("sizes", JSON.stringify(size));
		formdata.append("categoryId", product.categoryId);
		formdata.append("quantity", +product.quantity);
		formdata.append("shipping", +product.shipping);
		formdata.append("description", product.description);
		formdata.append("isSpecial", product.isSpecial.toString());
		addProduct(formdata)
	}
	return (
		<Fragment>
			<Breadcrumb title="Add Product" parent="Physical" />

			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<Card.Header>
								<h5>Add Product</h5>
							</Card.Header>
							<Card.Body>

								<Form onSubmit={(e) => {
									e.preventDefault()
									saveProduct()
								}}>
									{/* <Form.Row>
										<Form.Label>Select Product Image : </Form.Label>
										<input
											type="file"
											name="images"
											onChange={handleChange}
										/>
									</Form.Row>
									<Form.Row>
										<Form.Label>Select Another Product Image : </Form.Label>
										<input
											type="file"
											name="images2"
											onChange={handleChange}
										/>
									</Form.Row> */}
									<Form.Row>
										<Form.Label>Select Category</Form.Label>
										<Col xs="12">
											<select onChange={handleChange} name="categoryId" className="form-control form-control-md">
												<option>Select...</option>
												{
													categories?.map((item, index) => (
														<option key={index} value={item._id}>{item.name}</option>
													))
												}
											</select>
										</Col>

									</Form.Row>
									<Form.Row>
										<Col xs="6">
											<Form.Label>Product Name</Form.Label>
											<Form.Control
												type="text"
												placeholder="name of product"
												name="name"
												onChange={handleChange}
												required
											/>
										</Col>
										<Col xs="6">
											<Form.Label>Product Quantity</Form.Label>
											<Form.Control
												type="number"
												name="quantity"
												onChange={handleChange}
												required
											/>
										</Col>
									</Form.Row>
									<Form.Row>
										<Col xs="4">
											<Form.Label>Price</Form.Label>
											<Form.Control
												type="number"
												placeholder="Enter Product Price"
												name="realPrice"
												onChange={handleChange}
												required
											/>
										</Col>
										<Col xs="4">
											<Form.Label>Discount</Form.Label>
											<InputGroup>
												<Form.Control
													type="number"
													name="discount"
													onChange={handleChange}
													required
												/>
												<InputGroup.Text>
													%
														</InputGroup.Text>
											</InputGroup>

										</Col>
										<Col xs="4">
											<Form.Label>Shipping</Form.Label>
											<Form.Control
												type="number"
												name="shipping"
												onChange={handleChange}
												required
											/>
										</Col>
									</Form.Row>
									<Form.Row>
										<Col xs="12"><Form.Label>Sizes Available</Form.Label></Col>
										{
											size?.map((item, index) => (
												<Col xs="4" key={index}>
													<Card style={{ padding: '10px' }}>
														<b style={{ right: '10%', color: 'red', position: 'absolute', cursor: 'pointer' }} onClick={() => removeSize(index)}> X</b>
														<p>Size {index + 1}</p>
														<Form.Group>
															<Form.Control
																type="text"
																placeholder="name of size"
																name="name"
																onChange={(e) => handleSizeChange(e, index)}
															/>
														</Form.Group>
														<Form.Group>
															<Form.Control
																type="number"
																placeholder="number available"
																name="percentage"
																onChange={(e) => handleSizeChange(e, index)}
															/>
														</Form.Group>
													</Card>
												</Col>

											))
										}
										<Col xs="4">
											<Button onClick={addSize}>
												Add Another Size
													</Button>
										</Col>
									</Form.Row>
									<Form.Row>
										<Col xs="12"><Form.Label>Variants Available</Form.Label></Col>
										{
											variant?.map((item, index) => (
												<Col xs="4" key={index}>
													<Card style={{ padding: '10px' }}>
														<b style={{ right: '10%', color: 'red', position: 'absolute', cursor: 'pointer' }} onClick={() => removeVariant(index)}> X</b>
														<p>Variant {index + 1}</p>
														<Form.Group>
															<Form.Control
																type="text"
																placeholder="name of variant"
																name="name"
																onChange={(e) => handleVariantChange(e, index)}
															/>
														</Form.Group>
														<Form.Group>
															<Form.Control
																type="number"
																placeholder="number available"
																name="percentage"
																onChange={(e) => handleVariantChange(e, index)}
															/>
														</Form.Group>
														<Form.Row>
															<Form.Label>Variant Image 1 : </Form.Label>
															<input
																type="file"
																name="image"
																onChange={(e) => handleImagesChange(e, index)}
															/>
														</Form.Row>
														<Form.Row>
															<Form.Label>Variant Image 2 : </Form.Label>
															<input
																type="file"
																name="image"
																onChange={(e) => handleImagesChange(e, index)}
															/>
														</Form.Row>
													</Card>
												</Col>

											))
										}
										<Col xs="4">
											<Button onClick={addVariant}>
												Add Another Variant
													</Button>
										</Col>
									</Form.Row>
									<Form.Row>
										<Col xs="12">
											<Form.Label>Description</Form.Label>
											<Form.Control
												type="text"
												as="textarea"
												placeholder="enter product description"
												name="description"
												required
												onChange={handleChange}
											/>
										</Col>

									</Form.Row>
									<Form.Row>
										<Col xs="12">
											<br />
											<Form.Label>Check if it's a special product</Form.Label>
											<input
												type="checkbox"
												name="isSpecial"
												style={{ marginLeft: '10px' }}
												onChange={handleChange}
												value={product.isSpecial}
												checked={product.isSpecial}
											/>
										</Col>

									</Form.Row>
									<Row>
										<Col xs="12">
											<Button type="submit" >{isLoading ? 'Loading...' : 'Submit'}</Button>
										</Col>
									</Row>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Add_product;
{/* <Form
className="needs-validation add-product-form"
onSubmit={saveProduct}
>
<div className="form form-label-center">
	<FormGroup className="form-group mb-3 Form.row">
		<Label className="col-xl-3 col-sm-4 mb-0">
			Product Name :
		</Label>
		<div className="col-xl-8 col-sm-7">
			<Input
				className="form-control"
				name="name"
				id="validationCustom01"
				type="text"
				required
				value={product.name}
				onChange={handleChange}
				required
			/>
		</div>
		<div className="valid-feedback">Looks good!</div>
	</FormGroup>
	<FormGroup className="form-group mb-3 Form.row">
		<Label className="col-xl-3 col-sm-4 mb-0">
			Price :
		</Label>
		<div className="col-xl-8 col-sm-7">
			<Input
				className="form-control mb-0"
				name="price"
				id="validationCustom02"
				type="number"
				required
				value={product.saleprice}
				onChange={handleChange}
				required

			/>
		</div>
		<div className="valid-feedback">Looks good!</div>
	</FormGroup>
	<FormGroup className="form-group mb-3 Form.row">
		<Label className="col-xl-3 col-sm-4 mb-0">
			Discount Price :
		</Label>
		<div className="col-xl-8 col-sm-7">
			<Input
				className="form-control "
				name="product_code"
				id="validationCustomUsername"
				type="number"
				required
				onChange={handleChange}
				required
				value={product.discount}
			/>
		</div>
		<div className="invalid-feedback offset-sm-4 offset-xl-3">
			Please choose Valid Code.
		</div>
	</FormGroup>
</div>
<Form>
	<FormGroup className="form-group Form.row">
		<Label className="col-xl-3 col-sm-4 mb-0">
			Select Size :
		</Label>
		<div className="col-xl-8 col-sm-7">
			<select
				className="form-control digits"
				id="exampleFormControlSelect1"
			>
				<option>Small</option>
				<option>Medium</option>
				<option>Large</option>
				<option>Extra Large</option>
			</select>
		</div>
	</FormGroup>
	<FormGroup className="form-group Form.row">
		<Label className="col-xl-3 col-sm-4 mb-0">
			Total Products :
		</Label>
		<fieldset className="qty-box ml-0">
			<div className="input-group bootstrap-touchspin">
				<div className="input-group-prepend">
					<Button
						className="btn btn-primary btn-square bootstrap-touchspin-down"
						type="button"
						onClick={DecreaseItem}
					>
						<i className="fa fa-minus"></i>
					</Button>
				</div>
				<div className="input-group-prepend">
					<span className="input-group-text bootstrap-touchspin-prefix"></span>
				</div>
				<Input
					className="touchspin form-control"
					type="text"
					value={product.quantity}
					onChange={handleChange}
					required
				/>
				<div className="input-group-append">
					<span className="input-group-text bootstrap-touchspin-postfix"></span>
				</div>
				<div className="input-group-append ml-0">
					<Button
						className="btn btn-primary btn-square bootstrap-touchspin-up"
						type="button"
						onClick={IncrementItem}
					>
						<i className="fa fa-plus"></i>
					</Button>
				</div>
			</div>
		</fieldset>
	</FormGroup>
	<FormGroup className="form-group Form.row">
		<Label className="col-xl-3 col-sm-4">
			Add Description :
		</Label>
		<div className="col-xl-8 col-sm-7 description-sm">
			<Input
				name="description"
				type="textarea"
				Form.rows={6}
				value={product?.description}
				required
				onChange={handleChange}
				required
			/>
			{/* <CKEditors
				activeclassName="p10"
				events={{
					blur: onBlur,
					afterPaste: afterPaste,
					change: onChange,
				}}
			/> 
		</div>
	</FormGroup>
</Form>
<div className="offset-xl-3 offset-sm-4">
	<Button type="submit" color="primary">
		Add
	</Button>
	<Button type="button" color="light">
		Discard
	</Button>
</div>
</Form> */}
