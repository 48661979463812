import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Slider from "react-slick";
import "../../../assets/scss/slick.scss";
import "../../../assets/scss/slick-theme.scss";
import { Edit, Trash2, Slash, CheckCircle } from "react-feather";

import Rating from "react-simple-star-rating";

// image import
import two from "../../../assets/images/pro3/2.jpg";
import twentySeven from "../../../assets/images/pro3/27.jpg";
import one from "../../../assets/images/pro3/1.jpg";
import size_chart from "../../../assets/images/size-chart.jpg";
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
} from "reactstrap";
import { useGlobal } from "../../../services/Context";
import { useHistory, useParams } from "react-router";
const Product = () => {
    const { products, getProducts, deleteProduct, updateProductStatus } = useGlobal()
    const history = useHistory()
    const params = useParams()
    const [open, setOpen] = useState(false);
    const [product, setproduct] = useState([])
    const [quantity, setQuantity] = useState(1);
    const [rating, setRating] = useState(1);
    const [nav, setNav] = useState({
        nav1: null,
        nav2: null,
    });


    const handleChange = (event) => {
        setQuantity(parseInt(event.target.value));
    };
    const suspendAProduct = (id) => {
        let confirmDelete = window.confirm("Are you sure you want to suspend this product");
        if (confirmDelete) {
            updateProductStatus({ suspend: true }, id)
        }
    }
    const unsuspendAProduct = (id) => {
        let confirmDelete = window.confirm("Are you sure you want to activate this product");
        if (confirmDelete) {
            updateProductStatus({ suspend: false }, id)
        }
    }
    const deleteAProduct = (id) => {
        let confirmDelete = window.confirm("Are you sure you want to delete this product");
        if (confirmDelete) {
            deleteProduct(id)
        }
    }

    useEffect(() => {
        if (products === null) {
            getProducts()
        }
        setproduct(products?.filter((item) => item?._id === params.id))
        setNav({
            nav1: Slider.slider1,
            nav2: Slider.slider2,
        });
    }, [products]);

    return (
        <Fragment>
            <Breadcrumb title="Product Detail" parent="Physical" />

            <Container fluid={true}>
                {
                    product?.map((item, index) => (
                        <Card key={index}>
                            <Row className="product-page-main card-body">
                                <Col xl="5">
									<b>swipe to slide</b>
                                    <Slider
                                        asNavFor={nav.nav1}
                                        ref={(slider) => (Slider.slider1 = slider)}
                                        className="product-slider"
                                    >
                                        {
                                            item?.variants?.map((element, index) => (
                                                <React.Fragment key={index}>
                                                    {
                                                        element?.images?.map((img, idx) => (
                                                            <div className="item" key={idx}>
                                                                <img className="img-fluid" src={img || twentySeven} alt="" />
                                                            </div>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </Slider>

                                    <Slider
                                        asNavFor={nav.nav2}
                                        ref={(slider) => (Slider.slider2 = slider)}
                                        slidesToShow={2}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        className="small-slick"
                                    >
                                        {
                                            item?.variants?.map((element, index) => (
                                                <React.Fragment key={index}>
                                                    {
                                                        element?.images?.map((img, idx) => (
                                                            <div className="item" key={idx}>
                                                                <img className="img-fluid" src={img || twentySeven} alt="" />
                                                            </div>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </Slider>
                                </Col>
                                <Col xl="7">
                                    <div className="product-page-details product-right mb-0">

                                        <h2>{item?.name}</h2>
                                        <div style={{ fontSize: 27, height: 31 }}>
                                            <Rating
                                                ratingValue={rating}
                                                size={25}
                                                stars={5}
                                                transition
                                                name="rate1"
                                                emptyColor="black"
                                            />
                                        </div>
                                        <hr />
                                        <h6 className="product-title">product details</h6>
                                        <p>
                                            {item?.description}
                                        </p>
                                        <div className="product-price digits mt-2">
                                            <h3>
                                                €{item?.salePrice}<del>€{item?.realPrice}</del>
                                            </h3>
                                        </div>
                                        <ul className="color-variant">
                                            {
                                                item?.variants?.map((elem, index) => (
                                                    <li key={index} style={{ backgroundColor: `${elem?.name}`, boxShadow: '1px 2px 3px gray' }}></li>
                                                ))
                                            }
                                            {/* <li className="bg-light1"></li>
														<li className="bg-light2"></li> */}
                                        </ul>
                                        <hr />
                                        <h6 className="product-title size-text">
                                            {/* select size */}
                                            <span className="pull-right">
                                                <a
                                                    href="#javaScript"
                                                    data-toggle="modal"
                                                    data-target="#sizemodal"
                                                >
                                                    size chart
										</a>
                                            </span>
                                        </h6>
                                        <Modal isOpen={open}>
                                            <ModalHeader>
                                                <img
                                                    src={size_chart}
                                                    alt=""
                                                    className="img-fluid blur-up lazyloaded"
                                                />
                                            </ModalHeader>
                                        </Modal>
                                        <div className="size-box">
                                            <ul>
                                                {
                                                    item?.sizes?.map((elem, index) => (
                                                        <li key={index} style={{ boxShadow: '1px 2px 3px gray' }}><a href="#">{elem.name}</a></li>
                                                    ))
                                                }
                                                {/* <li className="bg-light1"></li>
														<li className="bg-light2"></li> */}
                                            </ul>
                                        </div>
                                        <div className="add-product-form">
                                            <h6 className="product-title">quantity</h6>
                                            <fieldset className="qty-box mt-2 ml-0">
                                                <div className="input-group bootstrap-touchspin">
                                                    {/* <div className="input-group-prepend">
														<button
															className="btn btn-primary btn-square bootstrap-touchspin-down"
															type="button"
															onClick={DecreaseItem}
														>
															<i className="fa fa-minus"></i>
														</button>
													</div>
													<div className="input-group-prepend">
														<span className="input-group-text bootstrap-touchspin-prefix"></span>
													</div> */}
                                                    <input
                                                        className="touchspin form-control"
                                                        type="text"
                                                        value={item?.quantity}
                                                        onChange={(event) => handleChange(event)}
                                                        disabled
                                                    />
                                                    {/* <div className="input-group-append">
														<span className="input-group-text bootstrap-touchspin-postfix"></span>
													</div>
													<div className="input-group-append ml-0">
														<button
															className="btn btn-primary btn-square bootstrap-touchspin-up"
															type="button"
															onClick={IncrementItem}
														>
															<i className="fa fa-plus"></i>
														</button>
													</div> */}
                                                </div>
                                            </fieldset>
                                        </div>
                                        <hr />
                                        {/* <h6 className="product-title">Time Reminder</h6>
										<div className="timer">
											<p id="demo">
												<span>
													25 <span className="padding-l">:</span>{" "}
													<span className="timer-cal">Days</span>{" "}
												</span>
												<span>
													22 <span className="padding-l">:</span>{" "}
													<span className="timer-cal">Hrs</span>{" "}
												</span>
												<span>
													13 <span className="padding-l">:</span>{" "}
													<span className="timer-cal">Min</span>{" "}
												</span>
												<span>
													57 <span className="timer-cal">Sec</span>
												</span>
											</p>
										</div>
										<div className="m-t-15">
											<Button color="primary" className="m-r-10" type="button">
												Add To Cart
									</Button>
											<Button color="secondary" type="button">
												Buy Now
									</Button>
										</div> */}
                                        <div className="product-hover" style={{ display: 'inline', float: 'right' }}>
                                            <ul>
                                                <li>
                                                    <Button color="btn" type="button" onClick={() => history.push(`/products/physical/edit-product/${item?._id}`)}>
                                                        <Edit className="editBtn" />
                                                    </Button>
                                                </li>
                                                <li>
                                                    {
                                                        item?.isActive ?
                                                            <Button color="btn" type="button" onClick={() => suspendAProduct(item?._id)}>
                                                                <Slash className="deleteBtn" />
                                                            </Button> :
                                                            <Button color="btn" type="button" onClick={() => unsuspendAProduct(item?._id)}>
                                                                <CheckCircle className="editBtn" />
                                                            </Button>
                                                    }

                                                </li>
                                                <li>
                                                    <Button color="btn" type="button" onClick={() => deleteAProduct(item?._id)}>
                                                        <Trash2 className="deleteBtn" />
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    ))
                }

            </Container>
        </Fragment>
    );
};

export default Product;
