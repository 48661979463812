import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { withRouter, useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useGlobal } from "../../services/Context";

const LoginTabset = () => {
	const { login, signup, isLoading } = useGlobal()
	const [user, setuser] = useState({
		email: "",
		password: ""
	})
	const history = useHistory();

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	const routeChange = () => {
		// history.push(`${process.env.PUBLIC_URL}/dashboard`);
		history.push(`/dashboard`);

	};
	const handleChange = (e) => {
		setuser({
			...user,
			[e.target.name]: e.target.value
		})
	}
	return (
		<div>
			<Fragment>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							Login
						</Tab>
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<Unlock />
							Register
						</Tab>
					</TabList>

					<TabPanel>
						<Form className="form-horizontal auth-form"
							onSubmit={(e) =>{ e.preventDefault(); login(user)}}
						>
							<FormGroup>
								<Input
									required=""
									name="email"
									type="email"
									className="form-control"
									placeholder="email"
									value={user.email}
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									value={user.password}
									onChange={handleChange}
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox mr-sm-2">
									<Input
										type="checkbox"
										className="custom-control-input"
										id="customControlAutosizing"
									/>
									<Label className="d-block">
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
										/>
										Remember Me{" "}
										<span className="pull-right">
											{" "}
											<a href="/#" className="btn btn-default forgot-pass p-0">
												lost your password
											</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								>
									{isLoading ? "Loading...": "Login"}
								</Button>
							</div>
							{/* <div className="form-footer">
								<span>Or Login up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div> */}
						</Form>
					</TabPanel>
					<TabPanel>
						<Form className="form-horizontal auth-form"
									onSubmit={(e) =>{ e.preventDefault(); signup(user)}}
						>
							<FormGroup>
								<Input
									required=""
									name="email"
									type="email"
									className="form-control"
									placeholder="email"
									value={user.email}
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									value={user.password}
									onChange={handleChange}
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox mr-sm-2">
									<Input
										type="checkbox"
										className="custom-control-input"
										id="customControlAutosizing"
									/>
									<Label className="d-block">
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
										/>
										I agree all statements in{" "}
										<span>
											<a href="/#">Terms &amp; Conditions</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								>
									{isLoading ? "Loading...": "Register"}
								</Button>
							</div>
							{/* <div className="form-footer">
								<span>Or Sign up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div> */}
						</Form>
					</TabPanel>
				</Tabs>
			</Fragment>
		</div>
	);
};

export default withRouter(LoginTabset);
