import React, { useContext, useEffect, useState } from 'react'
import { postRequest, getRequest, deleteRequestFetch, deleteRequest, patchRequest, postRequestFormData, putRequest } from './api'
import { toast } from "react-toastify";
import { useHistory } from 'react-router';

// import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
const GlobalContext = React.createContext(null)

export const useGlobal = () => {
    const globalContext = useContext(GlobalContext)
    return globalContext
}

// // get user from local storage
// const getLogged = localStorage.getItem('data');
// const logged = JSON.parse(getLogged);


const Context = ({ children }) => {
    const [msg, setmsg] = useState(null)
    const [currentUser, setcurrentUser] = useState()
    const [categories, setcategories] = useState(null)
    const [products, setproducts] = useState(null)
    const [aproduct, setproduct] = useState(null)
    const [users, setusers] = useState(null)
    const [orders, setorders] = useState(null)
    const [specialorders, setspecialorders] = useState(null)
    const [profile, setprofile] = useState(null)
    const [isLoading, setisLoading] = useState(false)
    const [landing, setlanding] = useState(null)
    const history = useHistory()

    useEffect(() => {
        const getLogged = localStorage.getItem('data');
        const logged = JSON.parse(getLogged);
        // if (products === null && !isLoading) {
        //     getProducts();
        // }
        // if (categories === null && !isLoading) {
        //     getCategories();
        // }
        // if (profile === null && !isLoading) {
        //     getProfile()
        // }
        // if (orders === null && !isLoading) {
        //     getOrders()
        // }
        // if (users === null && !isLoading) {
        //     getUsers()
        // }
        setcurrentUser(logged)

    }, [])

    // changes loader state and visibility
    const startLoading = (id) => {
        setmsg("")
        setisLoading(true)

    }
    const endLoading = (id) => {
        setisLoading(false)
    }

    const login = async (body) => {
        startLoading(1)
        const url = "admin/auth/signin"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    console.log(response.data)
                    localStorage.setItem('data', JSON.stringify(response.data))
                    toast.success("Signed In Successfully !");
                    // history.push()
                    window.location.replace('/dashboard')
                    // setmsg(feedbackMsg(response.message) || null)
                }
                else {
                    // setmsg(feedbackMsg(response.error) || null)
                    setTimeout(() => {
                        toast.error(response.error);
                        setmsg(null)
                    }, 10000);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }

    const signup = async (body) => {
        startLoading(1)
        const url = "admin/auth/signup"
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    localStorage.setItem('data', JSON.stringify(response))
                    toast.success("Account Created Successfully !");
                    // getProfile()
                    history.push('/dashboard')

                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }


    const getLanding = async () => {
        startLoading(0)
        const url = `admin/landing`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setlanding(response.data)
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }
    const setLanding = async (body) => {
        startLoading(0)
        const url = `admin/landing`
        await postRequestFormData(url, body)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    getLanding()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }
    const uploadFile = async (body) => {
        endLoading()
        const url = `hidden/upload`
        const request = await postRequestFormData(url, body)
         return request    
    }
    const getCategories = async () => {
        startLoading(0)
        const url = `admin/category`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setcategories(response.data)
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }
    const deleteCategory = async (id) => {
        startLoading(1)
        const url = `admin/category/${id}`
        await deleteRequestFetch(url)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Deleted Successfully !");
                    getCategories()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.message);
            })
    }
    const addCategory = async (body) => {
        startLoading(1)
        const url = `admin/category`
        await postRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Added Successfully !");
                    getCategories()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }
    const updateCategory = async (body) => {
        startLoading(1)
        const url = `admin/category`
        await patchRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Updated Successfully !");
                    getCategories()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.message);
            })
    }

    // all testimonials
    const getProducts = async () => {
        startLoading(0)
        const url = `admin/product`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setproducts(response.data)
                }
                else {
                    toast.error(response.error);

                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);
            })
    }
    const getProduct = async (id) => {
        startLoading(0)
        const url = `admin/product/${id}`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setproduct(response.data)
                }
                else {
                    toast.error(response.error);

                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);
            })
    }
    const addProduct = async (body) => {
        startLoading(1)
        const url = `admin/product`
        await postRequestFormData(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Added Successfully !");
                    getProducts()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }
    const deleteProduct = async (id) => {
        startLoading(1)
        const url = `admin/product/${id}`
        await deleteRequestFetch(url, { productId: id })
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Deleted Successfully !");
                    getProducts()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }
    const editProduct = async (body) => {
        startLoading(1)
        const url = `admin/product`
        await putRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Updated Successfully !");
                    getProducts()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }
    const getProfile = async () => {
        startLoading(0)
        const url = `admin/auth/profile`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setprofile(response.data)
                    if (products === null ) {
                        getProducts();
                    }
                    if (categories === null ) {
                        getCategories();
                    }
                    if (orders === null) {
                        getOrders()
                    }
                    if (users === null) {
                        getUsers()
                    }

                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }

    const getUsers = async () => {
        startLoading(0)
        const url = `admin/user/users`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setusers(response.data)
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }
    const disableUser = async (id) => {
        startLoading(1)
        const url = `admin/user/${id}`
        await deleteRequestFetch(url, { userId: id })
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("User Disabled Successfully !");
                    getUsers()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }
    const enableUser = async (id) => {
        startLoading(1)
        const url = `admin/user/${id}`
        await postRequest(url, { userId: id })
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("User Enabled Successfully !");
                    getUsers()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.response.data.error);
            })
    }

    const getOrders = async () => {
        startLoading(0)
        const url = `admin/order/orders`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setorders(response.data)
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }
    const getSpecialOrders = async () => {
        startLoading(0)
        const url = `admin/order/special`
        await getRequest(url)
            .then(response => {
                endLoading(0)
                if (response.status) {
                    setspecialorders(response.data)
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(0)
                toast.error(res.message);

            })
    }
    const updateOrder = async (body) => {
        startLoading(1)
        const url = `admin/order/status`
        await patchRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Updated Order Status Successfully !");
                    getOrders();
                    getSpecialOrders()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.message);
            })
    }
    const updateProductStatus = async (body, id) => {
        startLoading(1)
        const url = `admin/product/${id}`
        await patchRequest(url, body)
            .then(response => {
                endLoading(1)
                if (response.status) {
                    toast.success("Updated Successfully !");
                    getProducts()
                }
                else {
                    toast.error(response.error);
                }

            }).catch((res) => {
                endLoading(1)
                toast.error(res.message);
            })
    }

    // const getExchangeRates = async () => {
    //     startLoading(0)
    //     const url = `https://freecurrencyapi.net/api/v2/latest?apikey=1b9e4000-6932-11ec-95d1-17445ac5d346`
    //     await getRequestExternal(url)
    //         .then(response => {
    //             endLoading(0)
    //             if (response) {
    //                 // setexchangerate(response?.data)
    //                 localStorage.setItem('exchangerate', JSON.stringify(response?.data))
    //             }
    //             else {
    //                 setmsg("error fetching exchnge rates")
    //                 setTimeout(() => {
    //                     setmsg(null)
    //                 }, 10000);
    //             }

    //         }).catch((res) => {
    //             endLoading(0)
    //             setmsg("error fetching exchnge rates")
    //             setTimeout(() => {
    //                 setmsg(null)
    //             }, 10000);
    //         })
    // }
    // if (getdate !== new Date().toDateString() || !rates) {
    //     localStorage.setItem('date', new Date().toDateString());
    //     getExchangeRates()
    // }
    const value = {
        msg,
        currentUser,
        categories,
        products,
        users,
        orders,
        specialorders,
        profile,
        isLoading,
        aproduct,
        landing,
        startLoading,
        endLoading,
        uploadFile,
        getSpecialOrders,
        signup,
        login,
        deleteCategory,
        updateCategory,
        addCategory,
        addProduct,
        getCategories,
        getProducts,
        deleteProduct,
        getOrders,
        getUsers,
        disableUser,
        enableUser,
        getProduct,
        editProduct,
        getProfile,
        getLanding,
        setLanding,
        updateOrder,
        updateProductStatus,
    }

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}

export default Context
