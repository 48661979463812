import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/listUser";
import Datatable from "../common/datatable";
import { useGlobal } from "../../services/Context";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { Table } from "react-bootstrap";
const List_user = () => {
	const { users, getUsers, isLoading, disableUser, enableUser } = useGlobal()
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [cat, setcat] = useState("");

	useEffect(() => {
		if (users === null) {
			getUsers()
		}
	}, [users])

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};
	const onOpenModal2 = () => {
		setOpen2(true);
	};

	const onCloseModal2 = () => {
		setOpen2(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="User List" parent="Users" />
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<h5>User Details</h5>
					</CardHeader>
					<CardBody>
						{/* <div className="btn-popup pull-right">
							<Link to="/users/create-user" className="btn btn-secondary">
								Create User
							</Link>
						</div> */}
						<div className="clearfix"></div>
						<div id="basicScenario" className="product-physical">
							<Table>
								<thead>
									<tr>
										<th></th>
										<th>Name</th>
										<th>Email</th>
										<th>Orders</th>
										<th>Creation Date</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{users?.map((item, index) => (
										<tr key={index}>
											<td> <img src={item.profileImg} alt="img" width="40px" /></td>
											<td>{item.firstName} {item.lastName}</td>
											<td>{item.email}</td>
											<td>{item?.orders?.length}</td>
											<td>
												{new Date(
													item.createdAt
												).toDateString()}
											</td>
											<td>
												{
													item.isActive ?
														<span
															onClick={() => {
																if (
																	window.confirm(
																		"Are you sure you wish to disable this user?"
																	)
																) {
																	disableUser(
																		item._id
																	);
																}
															}}
														>
															<i
																className="fa fa-ban"
																style={{
																	width: 35,
																	fontSize: 20,
																	padding: 11,
																	color: "#e4566e",
																}}
															></i>
														</span> :
														<span>
															<i
																onClick={() => {
																	if (
																		window.confirm(
																			"Are you sure you wish to enable this user?"
																		)
																	) {
																		enableUser(
																			item._id
																		);
																	}
																}}
																className="fa fa-check-circle"
																style={{
																	width: 35,
																	fontSize: 20,
																	padding: 11,
																	color: "rgb(40, 167, 69)",
																}}
															></i>
														</span>
												}



											</td>
										</tr>
									))}
								</tbody>
							</Table>

							{/* <Datatable
										myData={datacat || []}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										handleRemoveRow={deleteCategory}
										enableUser={enableUser}
										class="-striped -highlight"
									/> */}
						</div>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_user;
