import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/orders";
import Datatable from "../common/datatable";
import {
	Badge, Card, CardBody, CardHeader, Col, Container, Row,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
} from "reactstrap";
import { Table } from "react-bootstrap";
import { useGlobal } from "../../services/Context";

const Orders = () => {
	const { specialorders, getSpecialOrders, isLoading, updateOrder } = useGlobal()
	const [currentorder, setcurrentorder] = useState(null)
	const [open2, setOpen2] = useState(false);
	const [status, setstatus] = useState({
		isShipped: false,
		isDelivered: false,
		isCancelled: false
	})
	useEffect(() => {
		if (specialorders === null) {
			getSpecialOrders()
		}
		setstatus({
			...status,
			orderId: currentorder?._id,
			isShipped: currentorder?.isShipped,
			isDelivered: currentorder?.isDelivered,
			isCancelled: currentorder?.isCancelled
		})
	}, [currentorder])

	const handleChange = (e) => {
		setstatus({
			...status,
			[e.target.name]: e.target.checked
		})
	}
	const onOpenModal2 = (item) => {
		setcurrentorder(item)
		setOpen2(true);
	};

	const onCloseModal2 = () => {
		setOpen2(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Orders" parent="Sales" />

			<Container fluid={true}>
				<Row>
					<Col sm="12">
					<Card>
							<CardHeader>
								<h5>Manage Special Order</h5>
							</CardHeader>
							<CardBody className="order-datatable">
							<div id="basicScenario" className="product-physical">
							<Table responsive>
								<thead>
									<tr>
										<th></th>
										<th>Customer Name</th>
										<th>Customer Email</th>
										<th>Recepient</th>
										<th>Delivery Address</th>
										<th>Amount</th>
										<th>Shipping</th>
										<th>Status</th>
										<th>Creation Date</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{specialorders?.map((item, index) => (
										<tr key={index}>
											<td> <img src={item?.images[0] || ''} alt="img" width="40px" /></td>
											<td>{item?.customer?.firstName} {item?.customer?.lastName}</td>
											<td>{item?.customer?.email}</td>
											<td>{item?.delivery?.name}</td>
											<td>{item?.delivery?.address}</td>
											<td>€{item?.amount}</td>
											<td>€{item?.totalShipping}</td>
											<td>
												<Badge>{item?.isPaid ?"paid" : "not paid"}</Badge>
												<Badge>{item?.isDelivered ?"delivered" : "not delivered"}</Badge>
												<Badge>{item?.isReviewed ?"reviewed" : "not reviewed"}</Badge>
												<Badge>{item?.isShipped ?"shipped" : "not shipped"}</Badge>
												<Badge>{item?.isCancelled ?"cancelled" : "not canceled"}</Badge>
											</td>
											<td>
												{new Date(
													item?.createdAt
												).toDateString()}
											</td>
													<td>
														{new Date(
															item?.createdAt
														).toDateString()}
													</td>
													<td>
														{
															<span>
																<i
																	onClick={() => {
																		onOpenModal2(item)
																	}}
																	className="fa fa-check-circle"
																	style={{
																		width: 35,
																		fontSize: 20,
																		padding: 11,
																		color: "rgb(40, 167, 69)",
																	}}
																></i>
															</span>
														}



													</td>
												</tr>
											))}
										</tbody>
									</Table>

									{/* <Datatable
										myData={datacat || []}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										handleRemoveRow={deleteCategory}
										enableUser={enableUser}
										class="-striped -highlight"
									/> */}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<Modal
				isOpen={open2}
				toggle={onCloseModal2}
				style={{
					overlay: { opacity: 0.1 },
				}}
			>
				<ModalHeader
					toggle={onCloseModal2}
				>
					<h5
						className="modal-title f-w-600"
						id="exampleModalLabel2"
					>
						Update Order Status
                                                   </h5>
				</ModalHeader>
				<ModalBody>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							updateOrder(status)
						}}
					>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Shipped
                             </Label>
							<Input style={{ display: 'inline-block', margin: '10px 20px' }} type="checkbox" name="isShipped" checked={status.isShipped} value={status.isShipped} onChange={handleChange} />

						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Delivered
                             </Label>
							<Input style={{ display: 'inline-block', margin: '10px 20px' }} type="checkbox" name="isDelivered" checked={status.isDelivered} value={status.isDelivered} onChange={handleChange} />

						</FormGroup>
						<FormGroup>
							<Label
								htmlFor="recipient-name"
								className="col-form-label"
							>
								Cancelled
                             </Label>
							<Input style={{ display: 'inline-block', margin: '10px 20px' }} type="checkbox" name="isCancelled" checked={status.isCancelled} value={status.isCancelled} onChange={handleChange} />

						</FormGroup>
						<ModalFooter>
							<Button
								type="submit"
								color="primary"
							>
								{isLoading ? "Loading.." : 'Update'}

							</Button>
							<Button
								type="button"
								color="secondary"
								onClick={() =>
									onCloseModal2()
								}
							>
								Close
                     </Button>
						</ModalFooter>
					</Form>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};

export default Orders;
