import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { data } from "../../../assets/data/category";
import Datatable from "../../common/datatable";
import {
   Button,
   Card,
   CardBody,
   CardHeader,
   Col,
   Container,
   Form,
   FormGroup,
   Input,
   Label,
   Modal,
   ModalBody,
   ModalFooter,
   ModalHeader,
   Row,
} from "reactstrap";
import { useGlobal } from "../../../services/Context";
import { Table } from "react-bootstrap";

const Category = () => {
   const { categories, deleteCategory, updateCategory, addCategory, getCategories, isLoading } = useGlobal()
   useEffect(() => {
      if (categories === null) {
         getCategories()
      }
      setdatacat(categories)
   }, [categories])
   const [currentcat, setcurrentcat] = useState(null)
   const [open, setOpen] = useState(false);
   const [open2, setOpen2] = useState(false);
   const [datacat, setdatacat] = useState(categories);
   const [cat, setcat] = useState("");
   const onOpenModal = () => {
      setOpen(true);
   };

   const onCloseModal = () => {
      setOpen(false);
   };
   const onOpenModal2 = (item) => {
      setcurrentcat(item)
      setOpen2(true);
   };

   const onCloseModal2 = () => {
      setOpen2(false);
   };
   return (
      <Fragment>
         <Breadcrumb title="Category" parent="Physical" />
         {/* <!-- Container-fluid starts--> */}
         <Container fluid={true}>
            <Row>
               <Col sm="12">
                  <Card>
                     <CardHeader>
                        <h5>Products Category</h5>
                     </CardHeader>
                     <CardBody>
                        <div className="btn-popup pull-right">
                           <Button
                              type="button"
                              color="primary"
                              onClick={onOpenModal}
                              data-toggle="modal"
                              data-original-title="test"
                              data-target="#exampleModal"
                           >
                              Add Category
                           </Button>
                           <Modal isOpen={open} toggle={onCloseModal}>
                              <ModalHeader toggle={onCloseModal}>
                                 <h5
                                    className="modal-title f-w-600"
                                    id="exampleModalLabel2"
                                 >
                                    Add Physical Product
                                 </h5>
                              </ModalHeader>
                              <ModalBody>
                                 <Form>
                                    <FormGroup>
                                       <Label
                                          htmlFor="recipient-name"
                                          className="col-form-label"
                                       >
                                          Category Name :
                                       </Label>
                                       <Input
                                          type="text"
                                          className="form-control"
                                          value={cat}
                                          onChange={(e) =>
                                             setcat(
                                                e.target.value
                                             )
                                          }

                                       />
                                    </FormGroup>
                                 </Form>
                              </ModalBody>
                              <ModalFooter>
                                 <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => addCategory({ name: cat })}
                                 >
                                    {isLoading ? "Loading.." : 'Save'}

                                 </Button>
                                 <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => onCloseModal("VaryingMdo")}
                                 >
                                    Close
                                 </Button>
                              </ModalFooter>
                           </Modal>
                        </div>
                        <div className="clearfix"></div>
                        <div id="basicScenario" className="product-physical">
                           <Table>
                              <thead>
                                 <tr>
                                    <th>Name</th>
                                    <th>Creation Date</th>
                                    <th>Actions</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {datacat?.map((item, index) => (
                                    <tr key={index}>
                                       <td>{item.name}</td>
                                       <td>
                                          {new Date(
                                             item.createdAt
                                          ).toDateString()}
                                       </td>
                                       <td>
                                          <span
                                             onClick={() => {
                                                if (
                                                   window.confirm(
                                                      "Are you sure you wish to delete this item?"
                                                   )
                                                ) {
                                                   deleteCategory(
                                                      item._id
                                                   );
                                                }
                                             }}
                                          >
                                             <i
                                                className="fa fa-trash"
                                                style={{
                                                   width: 35,
                                                   fontSize: 20,
                                                   padding: 11,
                                                   color: "#e4566e",
                                                   cursor:'pointer'
                                                }}
                                             ></i>
                                          </span>

                                          <span>
                                             <i
                                                onClick={() => onOpenModal2(item)}
                                                className="fa fa-pencil"
                                                style={{
                                                   width: 35,
                                                   fontSize: 20,
                                                   padding: 11,
                                                   color: "rgb(40, 167, 69)",
                                                   cursor:'pointer'
                                                }}
                                             ></i>

                                          </span>
                                       </td>
                                    </tr>
                                 ))}
                              </tbody>
                           </Table>

                           {/* <Datatable
										myData={datacat || []}
										multiSelectOption={false}
										pageSize={10}
										pagination={true}
										handleRemoveRow={deleteCategory}
										updateCategory={updateCategory}
										class="-striped -highlight"
									/> */}
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Container>
         {/* <!-- Container-fluid Ends--> */}
         <Modal
            isOpen={open2}
            toggle={onCloseModal2}
            style={{
               overlay: { opacity: 0.1 },
            }}
         >
            <ModalHeader
               toggle={onCloseModal2}
            >
               <h5
                  className="modal-title f-w-600"
                  id="exampleModalLabel2"
               >
                  Edit Category
                                                   </h5>
            </ModalHeader>
            <ModalBody>
               <Form
                  onSubmit={(e) => {
                     e.preventDefault();
                     updateCategory({
                        name: cat,
                        categoryId: currentcat?._id,
                     });
                  }}
               >
                  <FormGroup>
                     <Label
                        htmlFor="recipient-name"
                        className="col-form-label"
                     >
                        Category Name :
                                                         </Label>
                     <Input
                        type="text"
                        className="form-control"
                        defaultValue={currentcat?.name}
                        onChange={(e) =>
                           setcat(
                              e.target.value
                           )
                        }
                     />
                  </FormGroup>
                  <ModalFooter>
                     <Button
                        type="submit"
                        color="primary"
                     >
                        {isLoading ? "Loading.." : 'Update'}

                     </Button>
                     <Button
                        type="button"
                        color="secondary"
                        onClick={() =>
                           onCloseModal2()
                        }
                     >
                        Close
                     </Button>
                  </ModalFooter>
               </Form>
            </ModalBody>
         </Modal>
      </Fragment>
   );
};

export default Category;
