
const axios = require('axios')
// import axios from 'axios';  
const baseurl = "https://ayanke-backend.onrender.com/"
var loggedUser;
var data;
// if (typeof window !== 'undefined') {
    loggedUser = localStorage.getItem('data');
    data = JSON.parse(loggedUser);
// }
let headers = {
    "Content-Type": "application/json",
    ayanke_access_token: `${data?.token}`,
};
export const getRequestExternal = async (url) => {
    console.log(data?.token)
    const request = await fetch(`${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
    return request.json()
}
export const getRequest = async (url) => {
console.log(data?.token)

    const request = await fetch(`${baseurl}${url}`, {
        method: "GET",
        headers: headers,
    })
    return request.json()
}
export const postRequest = async (url, body) => {
console.log(data)

    const request = await axios.post(`${baseurl}${url}`, body, { headers: headers })

    return request
    // const request = await fetch(`${baseurl}${url}`, { 
    //     method: "POST",
    //     headers: headers,
    //     body: JSON.stringify(body)
    // })
    // return request.json()
}
export const postRequestFormData = async (url, body) => {

    const request = await axios.post(`${baseurl}${url}`, body, { headers: headers })

    return request
    // const request = await fetch(`${baseurl}${url}`, {
    //     method: "POST",
    //     headers: headers,
    //     body: body,
    //     mode: 'no-cors'   
    // })
    // return request.json()
}
export const deleteRequest = async (url, body) => {
    const request = await axios.delete(`${baseurl}${url}`, body, { headers: headers })

    return request
    // const request = await fetch(`${baseurl}${url}`, {
    //     method: "DELETE",
    //     headers: headers,
    //     body: JSON.stringify(body)
    // })
    // return request.json()
}
export const deleteRequestFetch = async (url, body) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(body)
    })
    return request.json()
}
export const putRequest = async (url, body) => {
    const request = await axios.put(`${baseurl}${url}`, body, { headers: headers })

    return request
    // const request = await fetch(`${baseurl}${url}`, {
    //     method: "DELETE",
    //     headers: headers,
    //     body: JSON.stringify(body)
    // })
    // return request.json()
}
export const patchRequest = async (url, body) => {
    const request = await axios.patch(`${baseurl}${url}`, body, { headers: headers })

    return request
    // const request = await fetch(`${baseurl}${url}`, {
    //     method: "PATCH",
    //     headers: headers,
    //     body: JSON.stringify(body)
    // })
    // return request.json()
}



