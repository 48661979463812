import React, {useEffect} from "react";
import man from "../../../assets/images/dashboard/man.png";
import { useGlobal } from "../../../services/Context";

const UserPanel = () => {
	const { profile, getProfile } = useGlobal()
	useEffect(() => {
		// getProfile()
	}, [])
	return (
		<div>
			<div className="sidebar-user text-center">
				<div>
					<img
						className="img-60 rounded-circle lazyloaded blur-up"
						src={profile?.profileImg || man}
						alt=""
					/>
				</div>
				<h6 className="mt-3 f-14">{profile?.email}</h6>
				<p>Admin</p>
			</div>
		</div>
	);
};

export default UserPanel;
