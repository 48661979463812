import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Edit, Trash2, Slash, CheckCircle } from "react-feather";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useGlobal } from "../../../services/Context";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const Product_list = () => {
	const { products, deleteProduct, getProducts, isLoading, updateProductStatus } = useGlobal();
	const [current, setcurrent] = useState({ product: 0, variant: 0 })
	const history = useHistory()
	useEffect(() => {
		if (products === null) {
			getProducts()
		}
	}, [products])
	const deleteAProduct = (id) => {
		let confirmDelete = window.confirm("Are you sure you want to delete this product");
		if (confirmDelete) {
			deleteProduct(id)
		}
	}
	const suspendAProduct = (id) => {
		let confirmDelete = window.confirm("Are you sure you want to suspend this product");
		if (confirmDelete) {
			updateProductStatus({ suspend: true }, id)
		}
	}
	const unsuspendAProduct = (id) => {
		let confirmDelete = window.confirm("Are you sure you want to activate this product");
		if (confirmDelete) {
			updateProductStatus({ suspend: false }, id)
		}
	}
	const changePic = (productIdx, variantIdx) => {
		setcurrent({ product: productIdx, variant: variantIdx })
	}
	if (!isLoading && products !== null) {
		return (
			<Fragment>
				<Breadcrumb title="Product List" parent="Physical" />
				<Container fluid={true}>
					<Row className="products-admin ratio_asos">
						{products?.map((myData, i) => {
							return (
								<Col xl="3" sm="6" key={i}>
									<Card>
										<div className="products-admin">
											<CardBody className="product-box">
												<div className="img-wrapper">
													<div className="lable-block">
														{myData?.tag === "new" ? (
															<span className="lable3">{myData?.tag}</span>
														) : (
															""
														)}
														{myData?.discount === "on sale" ? (
															<span className="lable4">{myData?.discount}</span>
														) : (
															""
														)}
													</div>
													<div className="front">
														<a href="/#" className="bg-size">
															<img
																alt=""
																className="img-fluid blur-up bg-img lazyloaded"
																src={current.product === i ? myData?.variants[current.variant]?.images[0] : myData?.variants[0]?.images[0]}
															/>
														</a>
														<div className="product-hover">
															<ul>
																<li>
																	<Button color="btn" type="button" onClick={() => history.push(`/products/physical/edit-product/${myData?._id}`)}>
																		<Edit className="editBtn" />
																	</Button>
																</li>
																<li>
																	{
																		myData?.isActive ?
																			<Button color="btn" type="button" onClick={() => suspendAProduct(myData?._id)}>
																				<Slash className="deleteBtn" />
																			</Button> :
																			<Button color="btn" type="button" onClick={() => unsuspendAProduct(myData?._id)}>
																				<CheckCircle className="editBtn" />
																			</Button>
																	}

																</li>
																<li>
																	<Button color="btn" type="button" onClick={() => deleteAProduct(myData?._id)}>
																		<Trash2 className="deleteBtn" />
																	</Button>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<div className="product-detail">
													<div className="rating">
														<i className="fa fa-star"></i>
														<i className="fa fa-star"></i>
														<i className="fa fa-star"></i>
														<i className="fa fa-star"></i>
														<i className="fa fa-star"></i>
													</div>
													<a href="/#">
														{" "}
														<Link to={`/products/physical/product-list/${myData?._id}`}><h6 style={{ cursor: 'pointer' }}>{myData?.name}</h6></Link>

													</a>
													<h4>
														€{myData?.salePrice} <del>€{myData?.realPrice}</del>
													</h4>
													<ul className="color-variant">
														{
															myData?.variants?.map((item, index) => (
																<li key={index} onClick={() => changePic(i, index)} style={{ backgroundColor: `${item?.name}`, boxShadow: '1px 2px 3px gray' }}></li>
															))
														}
														{/* <li className="bg-light1"></li>
														<li className="bg-light2"></li> */}
													</ul>
												</div>
											</CardBody>
										</div>
									</Card>
								</Col>
							);
						})}
					</Row>
				</Container>
			</Fragment>
		);
	} else {
		return (
			<h1>Loading.....</h1>
		)
	}

};

export default Product_list;
