import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import MyDropzone from "../common/dropzone";
import Datatable from "../common/datatable";
import data from "../../assets/data/media";
import { Card, CardBody, CardHeader, Container, Form, Row, Col, Table, CardFooter, Button } from "reactstrap";
import { useGlobal } from "../../services/Context";

const Media = () => {
	const { setLanding, getLanding, landing, isLoading } = useGlobal()
	const [data, setdata] = useState({
		heroTextOne: landing?.heroTextOne,
		heroTextTwo: landing?.heroTextTwo,
		heroTextThree: landing?.heroTextThree,
		heroImages1: landing?.heroImages[0],
		heroImages2: landing?.heroImages[0],
		parallaxTextOne: landing?.parallaxTextOne,
		parallaxTextTwo: landing?.parallaxTextTwo,
		parallaxTextThree: landing?.parallaxTextThree,
		parallaxImage: landing?.parallaxImage,
	})

	useEffect(() => {
		if (landing === null) {
			getLanding()
		}
		setdata({ ...data, landing })
	}, [landing])

	const handleImageChange = (e) => {
		setdata({
			...data,
			[e.target.name]: e.target.files[0]
		})
	}
	const handleChange = (e) => {
		setdata({
			...data,
			[e.target.name]: e.target.value
		})
	}
	return (
		<Fragment>
			<Breadcrumb title="Media" parent="Media" />
			<Container fluid={true}>
				<Form onSubmit={(e) => {
					e.preventDefault()
					var formdata = new FormData();
					formdata.append("heroImages", data?.heroImages1);
					formdata.append("heroImages", data?.heroImages2);
					formdata.append("heroTextOne", data?.heroTextOne);
					formdata.append("heroTextTwo", data?.heroTextTwo);
					formdata.append("heroTextThree", data?.heroTextThree);
					formdata.append("parallaxImage", data?.parallaxImage);
					formdata.append("parallaxTextOne", data?.parallaxTextOne);
					formdata.append("parallaxTextTwo", data?.parallaxTextTwo);
					formdata.append("parallaxTextThree", data?.parallaxTextThree);
					setLanding(formdata)
					// formdata.append("mailingText", "Be the first to know wassup");
					// formdata.append("bio", "Ayanke is the mall you want to be all about.");
				}}>
					<Card>
						<CardHeader>
							<h5>Hero Section</h5>
						</CardHeader>
						<CardBody>
							<Row>
								<Col>
									<div>
										<label>Hero slider 1</label>
									</div>
									<input placeholder="Text" name="heroTextOne" onChange={handleChange} /><br /><br />
									<input type="file" accept="image/*" name="heroImages1" onChange={handleImageChange} />
								</Col>
								<Col>
									<div>
										<label>Hero slider 2</label>
									</div>
									<input placeholder="Text" name="heroTextTwo" onChange={handleChange} /><br /><br />
									<input type="file" accept="image/*" name="heroImages2" onChange={handleImageChange} />
								</Col>
								<Col>
									<div>
										<label>Hero slider mini text</label>
									</div>
									<input placeholder="Text" name="heroTextThree" onChange={handleChange} />
								</Col>
							</Row>


						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h5>Parallax Section</h5>
						</CardHeader>
						<CardBody>
							<input type="text" placeholder="text 1" name="parallaxTextOne" onChange={handleChange} />
							<input type="text" placeholder="text 2" name="parallaxTextTwo" onChange={handleChange} />
							<input type="text" placeholder="text 3" name="parallaxTextThree" onChange={handleChange} />
							<input type="file" accept="image/*" name="parallaxImage" onChange={handleImageChange} />
						</CardBody>

					</Card>
					<Card>
						<CardFooter>
							<Button type="submit">{isLoading? 'saving...' : 'Save'}</Button>
						</CardFooter>
					</Card>
				</Form>
				<Card>
					<CardHeader>
						<h5>Landing File List</h5>
					</CardHeader>
					<CardBody>
						<div
							id="batchDelete"
							className="category-table media-table coupon-list-delete"
						>
							<Table>
								<thead>

								</thead>
								<tbody>
									<tr>
										<td>Hero Slider mini text</td>
										<td>{landing?.heroTextThree}</td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Hero Slider 1</td>
										<td><img src={landing?.heroImages[0]} style={{ width: '40px' }} /></td>
										<td>{landing?.heroTextOne}</td>
										<td></td>
									</tr>
									<tr>
										<td>Hero Slider 2</td>
										<td><img src={landing?.heroImages[1]} style={{ width: '40px' }} /></td>
										<td>{landing?.heroTextTwo}</td>
										<td></td>
									</tr>
									<tr>
										<td>Parallax</td>
										<td><img src={landing?.parallaxImage} style={{ width: '40px' }} /></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Parallax Text</td>
										<td>{landing?.parallaxTextOne}</td>
										<td>{landing?.parallaxTextTwo}</td>
										<td>{landing?.parallaxTextThree}</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Media;
